import type { FC } from 'react';

import { SearchField } from '@features/SearchField';

import { NotificationButton } from './features/NotificationButton';
import { ProfileButton } from './features/ProfileButton';

export const Header: FC = () => {
  return (
    <header className="flex justify-end h-13 w-full gap-3">
      <SearchField />
      <NotificationButton />
      <ProfileButton />
    </header>
  );
};
