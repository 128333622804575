import { z } from 'zod';

export const userNameSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  patronim: z.string().nullable().optional(),
});

export type UserName = z.infer<typeof userNameSchema>;

export const getLastNameAndInitials = ({
  firstName,
  lastName,
  patronim,
}: UserName) => {
  const nameArray = [lastName, `${firstName[0]}.`];

  if (patronim) {
    nameArray.push(`${patronim[0]}.`);
  }

  return nameArray.join(' ');
};

export const getFullName = ({ firstName, lastName, patronim }: UserName) => {
  const nameArray = [lastName, firstName];

  if (patronim) {
    nameArray.push(patronim);
  }

  return nameArray.join(' ');
};

/**
 * Имена учеников всегда отображаем как фамилия + имя
 */
export const getStudentName = ({ lastName, firstName }: UserName) =>
  getFullName({ lastName, firstName });
