import { forwardRef, useEffect, useState } from 'react';

import { composeRefs } from '@radix-ui/react-compose-refs';
import MaskedInput from 'react-text-mask';

import { CalendarIcon, CrossIcon } from '@shared/assets';
import { Input } from '@shared/ui/inputs';

import { Calendar } from './features/Calendar';
import { DATE_MASK } from './lib/config';
import { getDateValueFromString, getValueStringFromDate } from './lib/helpers';
import { Popover, PopoverTrigger } from '../Popover';

import type { InputProps } from '@shared/ui/inputs';
import type { ChangeEvent, ForwardRefRenderFunction } from 'react';

export type DatePickerProps = {
  value?: Date | null;
  onChange?: (date: Date | null) => void;
  onBlur?: VoidFunction;
} & Omit<
  InputProps,
  'onChange' | 'onBlur' | 'value' | 'rightElement' | 'onChange'
>;

const DatePicker: ForwardRefRenderFunction<
  HTMLInputElement,
  DatePickerProps
> = (
  { disabled, value, onChange, onBlur: onBlurExternal, ...props },
  externalRef,
) => {
  const [opened, setOpened] = useState(false);
  const [valueStr, setValueStr] = useState(getValueStringFromDate(value));

  const handleOpenChange = (opened: boolean) => {
    if (!opened) {
      onBlurExternal?.();
    }
    setOpened(opened);
  };

  const handleClear = () => {
    onChange?.(null);
    handleOpenChange(false);
  };

  const handleSelect = (date: Date) => {
    onChange?.(date);
    handleOpenChange(false);
  };

  const handleBlur = () => {
    const date = getDateValueFromString(valueStr);

    onChange?.(date);

    if (!date) {
      setValueStr('');
    }

    onBlurExternal?.();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueStr(event.target.value);
  };

  useEffect(() => {
    setValueStr(getValueStringFromDate(value));
  }, [value]);

  const input = (
    <MaskedInput
      mask={DATE_MASK}
      value={valueStr}
      guide={false}
      onBlur={handleBlur}
      onChange={handleChange}
      render={(maskRef, { defaultValue, onBlur, onChange }) => (
        <Input
          {...props}
          ref={(element) =>
            element && composeRefs(maskRef, externalRef)(element)
          }
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          leftElement={
            disabled ? (
              <CalendarIcon />
            ) : (
              <PopoverTrigger asChild className="cursor-pointer">
                <CalendarIcon />
              </PopoverTrigger>
            )
          }
          rightElement={
            value &&
            !disabled && (
              <button type="button" onClick={handleClear}>
                <CrossIcon />
              </button>
            )
          }
        />
      )}
    />
  );

  if (disabled) {
    return input;
  }

  return (
    <Popover
      isAutoWidth={false}
      onOpenChange={handleOpenChange}
      isOpened={opened}
      parent={input}
    >
      <Calendar value={value} onChange={handleSelect} />
    </Popover>
  );
};

export default forwardRef(DatePicker);
