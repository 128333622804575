const MAIN = '/';
const AUTH = '/auth';
const USERS = '/users';
const CREATE_USER = '/users/create';
const UPLOAD_USERS = '/users/upload';
const EDIT_USER = '/users/edit';
const SUBJECTS = '/subjects';
const CREATE_SUBJECT = '/subjects/create';
const EDIT_SUBJECT = '/subjects/edit';
const CLASSES_AND_GROUPS = '/cg';
const CLASSES = CLASSES_AND_GROUPS + '/classes';
const CREATE_CLASS = CLASSES + '/create';
const EDIT_CLASS = CLASSES + '/edit';
const GROUPS = CLASSES_AND_GROUPS + '/groups';
const EDIT_GROUP = GROUPS + '/edit';
const CREATE_GROUP = GROUPS + '/create';
const ROOMS = '/rooms';
const CREATE_ROOM = '/rooms/create';
const EDIT_ROOM = '/rooms/edit';
const SCHEDULE = '/schedule';
const STATISTICS = '/statistics';
const SETTINGS = '/settings';
const PROFILE = '/profile';

const DEFAULT_CRUMB_BY_PAGE = {
  [USERS]: {
    title: 'Все пользователи',
    href: USERS,
  },
  [ROOMS]: {
    title: 'Все кабинеты',
    href: ROOMS,
  },
  [SUBJECTS]: {
    title: 'Все предметы',
    href: SUBJECTS,
  },
  [CLASSES]: {
    title: 'Все классы',
    href: CLASSES,
  },
  [GROUPS]: {
    title: 'Все группы',
    href: GROUPS,
  },
} as const;

export const PAGES = {
  MAIN: {
    title: 'главная',
    mask: MAIN,
  },
  AUTH: {
    mask: AUTH,
  },
  USERS: {
    title: 'пользователи',
    mask: USERS,
  },
  CREATE_USER: {
    mask: CREATE_USER,
    crumb: DEFAULT_CRUMB_BY_PAGE[USERS],
  },
  UPLOAD_USERS: {
    title: '',
    mask: UPLOAD_USERS,
    crumb: DEFAULT_CRUMB_BY_PAGE[USERS],
  },
  EDIT_USER: {
    title: '',
    mask: `${EDIT_USER}/:userId`,
    crumb: DEFAULT_CRUMB_BY_PAGE[USERS],
    create: (userId: number) => `${EDIT_USER}/${userId}`,
  },
  SUBJECTS: {
    title: 'предметы',
    mask: SUBJECTS,
  },
  CREATE_SUBJECT: {
    mask: CREATE_SUBJECT,
    crumb: DEFAULT_CRUMB_BY_PAGE[SUBJECTS],
  },
  EDIT_SUBJECT: {
    mask: `${EDIT_SUBJECT}/:subjectId`,
    create: (subjectId: number) => `${EDIT_SUBJECT}/${subjectId}`,
    crumb: DEFAULT_CRUMB_BY_PAGE[SUBJECTS],
  },
  CLASSES_AND_GROUPS: {
    title: 'классы и группы',
    mask: CLASSES_AND_GROUPS,
  },
  CLASSES: {
    title: 'классы',
    mask: CLASSES,
  },
  CREATE_CLASS: {
    mask: CREATE_CLASS,
    crumb: DEFAULT_CRUMB_BY_PAGE[CLASSES],
  },
  EDIT_CLASS: {
    mask: `${EDIT_CLASS}/:classId`,
    crumb: DEFAULT_CRUMB_BY_PAGE[CLASSES],
    create: (classId: number) => `${EDIT_CLASS}/${classId}`,
  },
  GROUPS: {
    title: 'группы',
    mask: GROUPS,
  },
  CREATE_GROUP: {
    mask: CREATE_GROUP,
    crumb: DEFAULT_CRUMB_BY_PAGE[GROUPS],
  },
  EDIT_GROUP: {
    mask: `${EDIT_GROUP}/:groupId`,
    crumb: DEFAULT_CRUMB_BY_PAGE[GROUPS],
    create: (groupId: number) => `${EDIT_GROUP}/${groupId}`,
  },
  ROOMS: {
    title: 'кабинеты',
    mask: ROOMS,
  },
  CREATE_ROOM: {
    mask: CREATE_ROOM,
    crumb: DEFAULT_CRUMB_BY_PAGE[ROOMS],
  },
  EDIT_ROOM: {
    title: '',
    mask: `${EDIT_ROOM}/:roomId`,
    create: (roomId: number) => `${EDIT_ROOM}/${roomId}`,
    crumb: DEFAULT_CRUMB_BY_PAGE[ROOMS],
  },
  SCHEDULE: {
    title: 'расписание',
    mask: SCHEDULE,
  },
  STATISTICS: {
    title: 'статистика',
    mask: STATISTICS,
  },
  SETTINGS: {
    title: 'настройки',
    mask: SETTINGS,
  },
  PROFILE: {
    mask: PROFILE,
  },
};
