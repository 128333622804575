import { getDigitsFromString, parseDateToFrontFormat } from '@shared/helpers';
import { startOfDay, parse, isValid } from 'date-fns';

export const DATE_INPUT_MASK_DIGITS_LENGTH = 8;

export const parseDateInput = (dateInput: string): Date | null => {
  const date = startOfDay(parse(dateInput, 'dd.MM.yyyy', new Date()));

  return isValid(date) ? date : null;
};

export const getDateValueFromString = (value?: string): Date | null => {
  if (!value) return null;

  const digits = getDigitsFromString(value);

  if (digits.length !== DATE_INPUT_MASK_DIGITS_LENGTH) {
    return null;
  }

  const date = parseDateInput(value);

  return date || null;
};

export const getValueStringFromDate = (date?: Date | null) => {
  return date ? parseDateToFrontFormat(date) : '';
};
