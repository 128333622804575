import { PublicLayout } from '@app/layout/PublicLayout';
import { RouteObject } from 'react-router-dom';

export const publicRouter: RouteObject[] = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: '/auth',
        lazy: async () => {
          const { AuthPage } = await import('@pages/AuthPage');

          return { Component: AuthPage };
        },
      },
    ],
  },
];
