import { memo, useState } from 'react';

import { ru } from 'date-fns/locale/ru';
import { DayPicker } from 'react-day-picker';

import { ArrowDownIcon } from '@shared/assets';

import { calendarTheme } from './calendar.theme';

import type { FC } from 'react';

export interface CalendarProps {
  value?: Date | null;
  onChange?: (date: Date) => void;
}

const Calendar: FC<CalendarProps> = ({ value, onChange }) => {
  const [currentMonth, setCurrentMunth] = useState(value ?? undefined);

  return (
    <DayPicker
      month={currentMonth}
      onMonthChange={setCurrentMunth}
      locale={ru}
      onDayClick={onChange}
      showOutsideDays={true}
      selected={value ?? undefined}
      className="p-3"
      classNames={calendarTheme}
      components={{
        IconLeft: () => <ArrowDownIcon className="h-4 w-4 rotate-90" />,
        IconRight: () => <ArrowDownIcon className="h-4 w-4 rotate-[270deg]" />,
      }}
    />
  );
};

export default memo(Calendar);
