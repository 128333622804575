import { api, apiUrls, createApiFn } from '@shared/api';
import { createSortingString } from '@shared/helpers';

import type { UserQueryKeys, UsersListQueryKeys } from './queryKeys';
import type { UserListModel, UserModel } from './types';
import type { QueryFunction } from '@tanstack/react-query';

export const userQueryFn = createApiFn<UserModel, UserQueryKeys, number>(
  ({ queryKey, signal }) => {
    return api.get(apiUrls.USERS.USER(queryKey[1]), { signal });
  },
);

export const currentUserQueryFn = createApiFn<UserModel, string[]>(() => {
  return api.get(apiUrls.USERS.CURRENT);
});

export const usersQueryFn: QueryFunction<
  UserListModel,
  UsersListQueryKeys,
  number
> = createApiFn<UserListModel, UsersListQueryKeys, number>(
  ({ queryKey, signal, pageParam }) => {
    const { sortId, sortDir, filters, search, isBlocked } = queryKey[1];
    const sortStr = createSortingString(sortId, sortDir);

    return api.get(
      apiUrls.USERS.LIST(pageParam, sortStr, filters, search, isBlocked),
      {
        signal,
      },
    );
  },
);
