import { globalVariables } from '@shared/helpers';

import { withQueryString } from './withQueryString';

import type { ApiSection } from './types';

const createUrl = (url: string): string =>
  `${globalVariables.BACKEND_URL}/${url}/`;

const apiSection: ApiSection = (...parts) => createUrl(parts.join('/'));

const apiJWT: ApiSection = (...parts) => apiSection('jwt', ...parts);
const apiUsers: ApiSection = (...parts) => apiSection('users', ...parts);
const apiRooms: ApiSection = (...parts) => apiSection('rooms', ...parts);
const apiClasses: ApiSection = (...parts) => apiSection('classes', ...parts);
const apiSubjects: ApiSection = (...parts) => apiSection('subjects', ...parts);
const apiGroups: ApiSection = (...parts) => apiSection('groups', ...parts);

export const apiUrls = {
  JWT: {
    CREATE: apiJWT('create'),
    REFRESH: apiJWT('refresh'),
    VERIFY: apiJWT('verify'),
  },
  USERS: {
    RECOVER_PASSWORD: apiUsers('recover_password'),
    CURRENT: apiUsers('me'),
    CREATE: apiUsers(),
    USER: (id: number) => apiUsers(id),
    LIST: (
      page = 1,
      order?: string,
      roles?: string[],
      search?: string,
      isBlocked?: boolean,
    ) =>
      withQueryString(apiUsers(), {
        role: roles?.length ? roles.join() : undefined,
        page,
        order,
        search: search || undefined,
        is_blocked: isBlocked,
      }),

    VALIDATE_EXCEL: apiUsers('excel/validate'),
  },

  ROOMS: {
    LIST: (order?: string, page = 1, search?: string) =>
      withQueryString(apiRooms(), {
        page,
        order,
        room_name: search || undefined,
      }),
    CREATE: apiRooms(),
    ROOM: (id: number) => apiRooms(id),
  },

  CLASSES: {
    CREATE: apiClasses(),
    LIST: (search?: string, order?: string, page = 1) =>
      withQueryString(apiClasses(), {
        page,
        order,
        class_name: search || undefined,
      }),
    CLASS: (id: number) => apiClasses(id),
  },

  SUBJECTS: {
    LIST: (order?: string, name?: string, page = 1) =>
      withQueryString(apiSubjects(), { page, order, name: name || undefined }),
    CREATE: apiSubjects(),
    SUBJECT: (id: number) => apiSubjects(id),
  },
  GROUPS: {
    LIST: ({
      subjectId,
      classId,
      teacherId,
      order,
      search,
      page = 1,
    }: {
      subjectId?: number;
      classId?: number;
      teacherId?: number;
      order?: string;
      page?: number;
      search?: string;
    }) =>
      withQueryString(apiGroups(), {
        subject_id: subjectId,
        class_id: classId,
        teacher_id: teacherId,
        order,
        page,
        search: search || undefined,
      }),
    GROUP: (id: number) => apiGroups(id),
    CREATE: apiGroups(),
  },
};
