import { Navigate } from 'react-router-dom';

import { ProtectedLayout } from '@app/layout/ProtectedLayout';
import { PAGES } from '@shared/helpers';

import type { RouteObject } from 'react-router-dom';

export const protectedRouter: RouteObject[] = [
  {
    element: <ProtectedLayout />,
    children: [
      {
        path: PAGES.MAIN.mask,
        lazy: () => import('@pages/MainPage'),
      },
      {
        path: PAGES.USERS.mask,
        children: [
          {
            path: PAGES.USERS.mask,
            lazy: () => import('@pages/UsersPage'),
          },
          {
            path: PAGES.CREATE_USER.mask,
            lazy: () => import('@pages/CreateUserPage'),
            handle: {
              crumb: PAGES.CREATE_USER.crumb,
            },
          },
          {
            path: PAGES.EDIT_USER.mask,
            lazy: () => import('@pages/EditUserPage'),
            handle: {
              crumb: PAGES.EDIT_USER.crumb,
            },
          },
          {
            path: PAGES.UPLOAD_USERS.mask,
            lazy: () => import('@pages/UploadUsersPage'),
            handle: {
              crumb: PAGES.UPLOAD_USERS.crumb,
            },
          },
        ],
      },
      {
        children: [
          {
            path: PAGES.CLASSES.mask,
            lazy: () => import('@pages/ClassesPage'),
          },
          {
            path: PAGES.CREATE_CLASS.mask,
            lazy: () => import('@pages/CreateClassPage'),
            handle: {
              crumb: PAGES.CREATE_CLASS.crumb,
            },
          },
          {
            path: PAGES.EDIT_CLASS.mask,
            lazy: () => import('@pages/EditClassPage'),
            handle: {
              crumb: PAGES.EDIT_CLASS.crumb,
            },
          },
          {
            path: PAGES.GROUPS.mask,
            lazy: () => import('@pages/GroupsPage'),
          },
          {
            path: PAGES.CREATE_GROUP.mask,
            lazy: () => import('@pages/CreateGroupPage'),
          },
          {
            path: PAGES.EDIT_GROUP.mask,
            lazy: () => import('@pages/EditGroupPage'),
          },
          {
            path: PAGES.CLASSES_AND_GROUPS.mask,
            Component: () => <Navigate to={PAGES.CLASSES.mask} replace />,
          },
        ],
      },
      {
        path: PAGES.SUBJECTS.mask,
        lazy: () => import('@pages/SubjectsPage'),
      },
      {
        path: PAGES.CREATE_SUBJECT.mask,
        lazy: () => import('@pages/CreateSubjectPage'),
        handle: {
          crumb: PAGES.CREATE_SUBJECT.crumb,
        },
      },
      {
        path: PAGES.EDIT_SUBJECT.mask,
        lazy: () => import('@pages/EditSubjectPage'),
        handle: {
          crumb: PAGES.EDIT_SUBJECT.crumb,
        },
      },

      {
        path: PAGES.ROOMS.mask,
        lazy: () => import('@pages/RoomsPage'),
      },
      {
        path: PAGES.CREATE_ROOM.mask,
        lazy: () => import('@pages/CreateRoomPage'),
        handle: {
          crumb: PAGES.CREATE_ROOM.crumb,
        },
      },
      {
        path: PAGES.EDIT_ROOM.mask,
        lazy: () => import('@pages/EditRoomPage'),
        handle: {
          crumb: PAGES.EDIT_ROOM.crumb,
        },
      },
      {
        path: PAGES.SCHEDULE.mask,
        element: <>schedule</>,
      },
      {
        path: PAGES.STATISTICS.mask,
        element: <>statistics</>,
      },
      {
        path: PAGES.SETTINGS.mask,
        element: <>settings</>,
      },
      {
        path: PAGES.PROFILE.mask,
        element: <>profile</>,
      },
    ],
  },
];
