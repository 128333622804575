import {
  format,
  startOfWeek,
  endOfWeek,
  addDays,
  isWithinInterval,
  set,
} from 'date-fns';

export interface DateRange {
  from: Date;
  to: Date;
}

export enum DayOfWeek {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THERSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export const DAYS_OF_WEEK_MAP: Record<DayOfWeek, string> = {
  [DayOfWeek.MONDAY]: 'Пн',
  [DayOfWeek.TUESDAY]: 'Вт',
  [DayOfWeek.WEDNESDAY]: 'Ср',
  [DayOfWeek.THERSDAY]: 'Чт',
  [DayOfWeek.FRIDAY]: 'Пт',
  [DayOfWeek.SATURDAY]: 'Сб',
  [DayOfWeek.SUNDAY]: 'Вс',
};

export const getDayOfWeekInRange = (
  range: DateRange,
  day: DayOfWeek,
): Date | null => {
  const { from, to } = range;

  if (!from || !to) {
    return null;
  }

  const weekStart = startOfWeek(from, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(to, { weekStartsOn: 1 });

  const targetDate = addDays(weekStart, day);

  if (isWithinInterval(targetDate, { start: weekStart, end: weekEnd })) {
    return targetDate;
  }

  return null;
};

export const isNowWithinRange = (range: DateRange) =>
  isWithinInterval(new Date(), { start: range.from, end: range.to });

export const applyTimeToDate = (time: string, date = new Date()) => {
  const [hours, minutes] = time.split(':');

  return set(date, { hours: Number(hours), minutes: Number(minutes) });
};

export const parseDateToServerFormat = (date?: Date | null) =>
  date ? format(date, 'yyyy-MM-dd') : undefined;
export const parseDateToFrontFormat = (date?: Date | null) =>
  date ? format(date, 'dd.MM.yyyy') : undefined;
export const parseDateWithTime = (date?: Date | null) =>
  date ? format(date, 'dd.MM.yyyy-HH:mm') : undefined;
