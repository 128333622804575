import { useCallback } from 'react';

import { AuthOperationKind } from '@shared/types';
import { useSearchParams } from 'react-router-dom';

export const useAuthOperationKind = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setOperationKind = useCallback((kind: AuthOperationKind) => {
    setSearchParams({ k: kind });
  }, []);

  const operationKind = (searchParams.get('k') ??
    AuthOperationKind.LOGIN) as AuthOperationKind;

  return { operationKind, setOperationKind };
};
