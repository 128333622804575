import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';

import { queryClient } from '@shared/api';
import { WidthProvider } from '@shared/helpers';

import { router } from './router';

function App() {
  return (
    <WidthProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </WidthProvider>
  );
}

export default App;
