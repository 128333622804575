import type { FC } from 'react';

import { ProSchoolLogoDark } from '@shared/assets';
import { PAGES } from '@shared/helpers';
import { Link } from 'react-router-dom';

import { NavigationList } from './features/NavigationList/NavigationList';

export const NavigationSidebar: FC = () => {
  return (
    <div className="relative h-full w-52 bg-bg-default rounded-3xl flex flex-col shrink-0 p-2">
      <Link to={PAGES.MAIN.mask}>
        <img src={ProSchoolLogoDark} className="w-full h-21" alt="logo" />
      </Link>

      <nav className="h-full mt-3 flex flex-col justify-between overflow-y-auto">
        <NavigationList />
      </nav>
    </div>
  );
};
