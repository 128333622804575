import type { userBaseSchema } from './schema';
import type {
  ApiError,
  ApiErrorKind,
  CommonApiError,
  FormFieldError,
  userNameSchema,
} from '@shared/helpers';
import type { ApiList } from '@shared/types';
import type { z } from 'zod';

export enum Role {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  PARENT = 'PARENT',
  STUDENT = 'STUDENT',
}

export type UserName = z.infer<typeof userNameSchema>;

export type UserBaseModel = z.infer<typeof userBaseSchema>;

type UserModelRaw<R extends Role, E extends object | null = null> = {
  role: R;
  extra: E;
} & UserBaseModel & {
    email: string;
    rawPassword: string;
    birthDate?: string | null;
    city?: string | null;
    phoneNumber?: string | null;
    comment?: string | null;
    isBlocked?: boolean;
  };

export type TeacherExtra = { isCurator: boolean; curatedClasses: number[] };
export type StudentExtra = { currentClass?: number | null };

export type AdminModel = UserModelRaw<Role.ADMIN>;
export type ParentModel = UserModelRaw<Role.PARENT>;
export type TeacherModel = UserModelRaw<Role.TEACHER, TeacherExtra>;
export type StudentModel = UserModelRaw<Role.STUDENT, StudentExtra>;

export type UserModel = TeacherModel | StudentModel | AdminModel | ParentModel;

export type UserListModel = ApiList<UserModel>;

export type CreateUserPayload = Pick<
  UserModel,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'patronim'
  | 'birthDate'
  | 'city'
  | 'phoneNumber'
  | 'comment'
  | 'role'
>;

export type UpdateUserPayload = {
  id: number;
  data: Partial<
    Pick<
      UserModel,
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'patronim'
      | 'birthDate'
      | 'city'
      | 'phoneNumber'
      | 'comment'
      | 'isBlocked'
    >
  >;
};

export type ValidationUserError = Partial<
  Record<keyof UserModel, FormFieldError[]>
>;

export type SaveUserError =
  | CommonApiError
  | ApiError<ApiErrorKind.VALIDATION, ValidationUserError[]>;

export type UserNotFoundError = CommonApiError;
