export const calendarTheme = {
  months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
  month: 'space-y-2',
  caption: 'flex justify-center pt-1 relative items-center',
  caption_label: 'text-xs capitalize',
  nav: 'space-x-1 flex items-center',
  nav_button_previous: 'absolute left-1',
  nav_button_next: 'absolute right-1',
  head_row: 'flex capitalize',
  head_cell: 'text-text-secondary rounded w-7.5 font-normal text-xs',
  row: 'flex w-full mt-2',
  cell: 'h-6 w-7.5 text-xs p-0 relative focus-within:z-20',
  day: 'flex items-center justify-center h-6 w-7.5 p-0 font-normal aria-value:opacity-100 cursor-pointer',
  day_selected: 'bg-decorative-blue text-text-default-inverted rounded',
  day_outside: 'day-outside text-text-disable aria-value:opacity-30',
};
